@import "~Styles/colors.module";
@import "~Styles/mixins.module";

.modal_view_pager {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 8;

  background: $color-dark;

  .close_icon {
    height: 24px;
    width: 24px;
    object-fit: contain;

    position: absolute;
    left: 20px;
    top: 20px;

    z-index: 8;
    @include on_click_button;
  }
}

.left_view_pager_container {
  background: $color-dark-base;
  min-width: 100px;
}

.project_details_container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 20;
}

.has_shadow {
  box-shadow: 0px 5px 12px 3px rgba(black, 0.2);
  background-color: $color-dark;
}

.header_content_container {
  max-width: 1024px;
  justify-content: flex-end;
}

.header_container {
  z-index: 2;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  min-height: 68px;
  transition: box-shadow 0.6s, background-color 0.6s ease;
  align-items: unset;

  .header_content {
    min-width: unset;
    margin: 0px 20px;
  }

  .cross_img {
    width: 20px;
    object-fit: contain;

    @include on_click_button;

    filter: drop-shadow(1px 2px 6px #000);
  }
  .project_link {
    z-index: 1;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    border: solid 1px white;
    padding: 2px 18px;
    text-decoration: none;
    color: white;

    @include on_click_button;
  }
}

.container {
  flex: 1;
  margin: 10px 10px 0px 10px;
  position: relative;

  .shadow_header {
    align-self: stretch;
    min-height: 48px;
    position: relative;
    margin: 0px 80px;
    z-index: 4;
  }

  // .content_container::-webkit-scrollbar {
  //   margin-right: -8px;
  //   width: 4px !important;
  // }

  // /* Track */
  // .content_container::-webkit-scrollbar-track {
  //   border-radius: 10px !important;
  // }

  // /* Handle */
  // .content_container::-webkit-scrollbar-thumb {
  //   background: rgba($color-dark, 0.3) !important;
  //   border-radius: 10px !important;
  // }
}

.content_container {
  overflow: auto;
  margin-top: 10px;
  z-index: 1;
  padding: 290px 0px 20px;

  .content {
    padding: 0px 80px;
    margin-bottom: 50px;
  }
}

@include large {
  .left_view_pager_container {
    display: none;
  }
  .header_content_container {
    max-width: unset;
  }

  .header_container {
    align-items: unset;

    .header_content {
      min-width: unset;
      margin: 0px 20px;
    }
  }

  .container {
    .shadow_header {
      margin: 0px 40px;
    }
  }

  .content_container {
    .content {
      padding: 0px 40px;
    }
  }
}

@include medium {
  .header_container {
    .header_content {
      margin: 0px 20px;
    }
  }

  .container {
    .shadow_header {
      margin: 0px 30px;
    }
  }

  .content_container {
    .content {
      padding: 0px 10px;
    }
  }
}
