@import "~Styles/colors.module";
@import "~Styles/mixins.module";

.sub_info_container {
  font-size: 15px;
  font-weight: 500;
  color: white;
  line-height: 1;
  padding: 0px 10px 10px;
  display: none;

  .title {
    color: white;
    margin-bottom: 2px;
  }

  .value {
    text-transform: capitalize;
  }
}

.description_container {
  font-size: 15px;
  color: white;

  .text {
    padding: 12px;
  }

  .header {
    margin-top: 24px;
    padding-left: 12px;
    font-size: 18px;
    font-weight: bold;
  }

  .points_container {
    padding: 0px 12px;

    .title {
    }

    .points_ul {
      margin: 6px 0px;
    }
  }
}

.highlight_green {
  background-color: rgba($color-dark-base, 0.5);
}

.highlight_blue {
  background-color: rgba($color-blue, 0.1);
}

@include small {
  .sub_info_container {
    display: flex;
    justify-content: flex-end;

    text-align: right;
    margin-bottom: 16px;

    .title {
      color: #999;
      margin-bottom: 5px;
    }
  }
}
